import axios                                            from 'axios'
import {
  StandardThunk,
  createGetFacebookBusiness,
  createRetrievingFacebookBusiness,
  createFailedRetrievingFacebookBusiness,
  createRetrievingFacebookPages,
  createGetFacebookPages,
  createFailedRetrievingFacebookPages,
  createRetrievingFacebookUnusedAdAccounts,
  createGetFacebookUnusedAdAccounts,
  createFailedRetrievingFacebookUnusedAdAccounts,
  createSubmittingFacebookAccountId,
  createFailedSubmittingFacebookAccountId,
  createRerievingAccountInfoFirestone,
  createGetAccountInfofireStone,
  createFailedRetrievingAccountInfoFirestone,
}                                                       from '../creators'
import businessUrl,{Success as Business}                from '../../model/http/Firestone/GetBusiness'
import pagesUrl,{Success as Pages}                      from '../../model/http/Firestone/GetFacebookPages'
import accountInfoUrl,{Success as AccountInfos}         from '../../model/http/Firestone/GetAccountInfo'
import AdAccountsUrl,{Success as AdAccounts}            from '../../model/http/Firestone/GetUnusedAdAccounts'
import submitUrl                                        from '../../model/http/Firestone/PostFacebookId'
import { getAccounts }                                  from '../Auth2/accounts'

export const getFacebookBusinesses = ():StandardThunk => (dispatch, getState) => {

  const token = getState().Login.User.token
  dispatch(createRetrievingFacebookBusiness(true))
  axios.get<Business[]>(businessUrl,{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetFacebookBusiness({
      businesses : response.data
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingFacebookBusiness(true))
  })
  .then(()=>{
    dispatch(createRetrievingFacebookBusiness(false))
  })
}

export const getFacebookPages = (businessId:number):StandardThunk => (dispatch, getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingFacebookPages(true))
  axios.get<Pages[]>(pagesUrl(businessId),{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetFacebookPages({
      pages : response.data,
      businessId,
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingFacebookPages(true))
  })
  .then(()=>{
    dispatch(createRetrievingFacebookPages(false))
  })
}

export const getFacebookUnusedAdAccounts = (businessId:number):StandardThunk => (dispatch, getState) => {
  const token = getState().Login.User.token
  dispatch(createRetrievingFacebookUnusedAdAccounts(true))
  axios.get<AdAccounts[]>(AdAccountsUrl(businessId),{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetFacebookUnusedAdAccounts({
      adAccounts : response.data,
      businessId,
    }))
  })
  .catch((error)=>{
    console.log(error)
    dispatch(createFailedRetrievingFacebookUnusedAdAccounts(true))
  })
  .then(()=>{
    dispatch(createRetrievingFacebookUnusedAdAccounts(false))
  })
}

export const submitFacebookId = (businessId:number,adAccountId:string,pageId:number,accountId:number):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  const selectedAgencyId = getState().Selection.Agency
  dispatch(createSubmittingFacebookAccountId(true))
  axios.post(submitUrl(businessId,adAccountId,pageId,accountId),{},{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(getAccounts(selectedAgencyId))
    dispatch(getAccountInfoFirestone(accountId))
  })
  .catch((error)=>{
    dispatch(createFailedSubmittingFacebookAccountId({failed:true,message:error.response.data.error}))
  })
  .then(()=>{
    dispatch(createSubmittingFacebookAccountId(false))
  })
}

export const getAccountInfoFirestone = (accountId:number):StandardThunk => (dispatch,getState) => {
  const token = getState().Login.User.token
  dispatch(createRerievingAccountInfoFirestone(true))
  axios.get<AccountInfos>(accountInfoUrl(accountId),{
    headers : {
      Authorization : `Bearer ${token}`
    }
  })
  .then(response=>{
    dispatch(createGetAccountInfofireStone({
      accountId,
      accountInfo : response.data
    }))
  })
  .catch(error=>{
    if(error.response.status === 404) {
      dispatch(createGetAccountInfofireStone({
        accountId,
        accountInfo : "NON_EXISTING"
      }))
    } else {
      dispatch(createFailedRetrievingAccountInfoFirestone(true))
    }
  })
  .then(()=>{
    dispatch(createRerievingAccountInfoFirestone(false))
  })
}
